
import { defineComponent, nextTick, onMounted, provide } from "vue";
import { initializeComponents } from "@/core/plugins/keenthemes";
import { messaging, token } from "@/core/plugins/firebase";
import { onMessage } from "firebase/messaging";
import { ElNotification } from "element-plus";
import { useRouter } from "vue-router";

// load all stores
import { useCategories } from "./store/categories";
import { useCustomers } from "./store/customers";
import { useMenus } from "./store/menus";
import { useOptions } from "./store/options";
import { useProducts } from "./store/products";
import { useStaffs } from "./store/staffs";
import { useOffers } from "./store/offers";
import { useSettings } from "./store/settings";
import { useSales } from "./store/sales";
import { useAuth } from "./store/auth";
import ApiService from "./core/services/ApiService";

export default defineComponent({
  name: "app",
  mounted() {
    // listen for new messages
    onMessage(messaging, (payload) => {
      ElNotification({
        offset: 150,
        type: "success",
        onClick: () => {
          this.$router.push("/sales");
        },
        title: payload.notification.title,
        message: payload.notification.body,
      });
    });
  },
  setup() {
    const router = useRouter();
    // if token and user is set to local storage, fetch user and settings
    if (!localStorage.getItem("token") && !localStorage.getItem("user")) {
      router.push({ name: "Sign in" });
      return {};
    }

    const authStore = useAuth();
    authStore.fetchUser();

    const settings = useSettings();
    settings.fetchSettings();

    const categoryStore = useCategories();
    categoryStore.fetchCategories();

    const menuStore = useMenus();
    menuStore.fetchMenus();

    const optionStore = useOptions();
    optionStore.fetchOptions();

    const productStore = useProducts();
    productStore.fetchProducts();

    const offerStore = useOffers();
    offerStore.fetchOffers();

    const customerStore = useCustomers();
    customerStore.fetchCount();
    customerStore.fetchCustomers(1, 10);
    customerStore.fetchReviews(1);

    const staffStore = useStaffs();
    staffStore.fetchCount();
    staffStore.fetchStaffs(1, 10);

    const saleStore = useSales();
    saleStore.fetchCount();
    saleStore.fetchSales(1, 10);

    provide("messaging", messaging);

    token.then((token) => {
      // eslint-disable-next-line
      // @ts-ignore
      ApiService.post("/v1/auth/firebase-token", { token });
    });

    onMounted(() => {
      nextTick(() => {
        initializeComponents();
      });
    });
  },
});
