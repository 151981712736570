import { defineStore } from "pinia";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";

export const useProducts = defineStore("products", {
  state: () => ({
    _products: [],
    _product: {},
    _count: 0,
    _loading: false,
  }),
  getters: {
    count(state) {
      return state._count;
    },
    products(state) {
      return state._products.sort((a, b) => {
        return a.order - b.order;
      });
    },
    product(state) {
      return state._product;
    },
    loading(state) {
      return state._loading;
    },
  },
  actions: {
    async fetchProducts() {
      this._loading = true;
      await ApiService.get("/v1/products")
        .then((response) => {
          this._products = response.data.payload;
          this._count = response.data.payload.length;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this._loading = false;
        });
    },
    async createProduct(product) {
      this._loading = true;
      await ApiService.post(`/v1/products`, product)
        .then((response) => {
          this._products.push(response.data.payload);
          this._count = this._products.length;

          ElNotification({
            title: "Success",
            message: "Product created successfully!",
            offset: 150,
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          ElNotification({
            title: "Error",
            message: error.response.data.message,
            offset: 150,
            type: "error",
          });
          return false;
        })
        .finally(() => {
          this._loading = false;
        });
    },
    async editProduct(product) {
      this._loading = true;
      await ApiService.patch(`/v1/products/`, product)
        .then((response) => {
          const index = this._products.findIndex(
            (p) => p.id === response.data.payload.id
          );
          this._products[index] = response.data.payload;

          ElNotification({
            title: "Success",
            message: "Product updated successfully!",
            offset: 150,
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          ElNotification({
            title: "Error",
            message: error.response.data.message,
            offset: 150,
            type: "error",
          });
          return false;
        })
        .finally(() => {
          this._loading = false;
        });
    },
    async deleteProduct(id: number | string) {
      await ApiService.delete(`/v1/products/${id}`)
        .then((response) => {
          if (response.data.status === "success") {
            const index = this._products.findIndex((menu) => menu.id === id);
            this._products.splice(index, 1);
            this._count = this._products.length;

            ElNotification({
              title: "Success",
              message: "Product deleted successfully!",
              offset: 150,
              type: "success",
            });
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this._loading = false;
        });
    },
  },
});
