import { defineStore } from "pinia";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";
import { useCategories } from "./categories";
import { useProducts } from "./products";

export const useMenus = defineStore("menus", {
  state: () => ({
    _menus: [],
    _currentMenu: null,
    _menu: {},
    _count: 0,
    _loading: false,
  }),
  getters: {
    menus(state) {
      return state._menus;
    },
    menu(state) {
      if (state._currentMenu) {
        const categories = useCategories();
        const products = useProducts();
        state._menu = state._menus.filter((e) => e.id == state._currentMenu)[0];
        /* eslint-disable */
        // @ts-ignore
        state._menu.categories = categories.categories.filter(
          (e) => e.menu_id == state._currentMenu
        );
        // sort categories by order
        // @ts-ignore
        // state._menu.categories.sort((a, b) => a.order - b.order);
        // @ts-ignore
        state._menu.categories.forEach((category) => {
          // @ts-ignore
          category.items = products.products.filter(
            (e) => e.category_id == category.id
          );
          // sort items by order
          // @ts-ignore
          // category.items.sort((a, b) => a.order - b.order);
        });
        /* eslint-enable */
        return state._menu;
      }
      return null;
    },
    count(state) {
      return state._count;
    },
    pages(state) {
      return Math.ceil(state._count / 10);
    },
    loading(state) {
      return state._loading;
    },
  },
  actions: {
    async fetchMenus() {
      this._loading = true;
      await ApiService.get("/v1/menus")
        .then((response) => {
          this._menus = response.data.payload;
          this._count = response.data.payload.length;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this._loading = false;
        });
    },
    async createMenu(menu) {
      this._loading = true;
      await ApiService.post(`/v1/menus`, menu)
        .then((response) => {
          this._menus.push(response.data.payload);
          ElNotification({
            title: "Success",
            message: "Menu created successfully!",
            offset: 150,
            type: "success",
          });

          // insert new item to menu
          return true;
        })
        .catch((error) => {
          ElNotification({
            title: "Error",
            message: error.response.data.message,
            offset: 150,
            type: "error",
          });
          return false;
        })
        .finally(() => {
          this._loading = false;
        });
    },
    async editMenu(menu) {
      this._loading = true;
      await ApiService.patch(`/v1/menus/`, menu)
        .then((response) => {
          if (response.data.status === "success") {
            ElNotification({
              title: "Success",
              message: "Menu updated successfully!",
              offset: 150,
              type: "success",
            });
          } else {
            ElNotification({
              title: "Error",
              message: "Something went wrong! Please refresh the page.",
              offset: 150,
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this._loading = false;
        });
    },
    async deleteMenu(id: number | string) {
      await ApiService.delete(`/v1/menus/${id}`)
        .then((response) => {
          if (response.data.status === "success") {
            this._menus.splice(
              this._menus.findIndex((menu) => menu.id === id),
              1
            );
            this._count = this._menus.length;

            ElNotification({
              title: "Success",
              message: "Menu deleted successfully!",
              offset: 150,
              type: "success",
            });
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this._loading = false;
        });
    },
    async exportMenus() {
      // download file (axios)
      ApiService.get(`/v1/menus/export`).then((response) => {
        // save file
        const url = window.URL.createObjectURL(
          new Blob([JSON.stringify(response.data)])
        );
        const link = document.createElement("a");
        const filename = "menus.json";

        if (link.download !== undefined) {
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
    },
    async saveOrder(products) {
      await ApiService.put(`/v1/products`, products)
        .then((response) => {
          if (response.data.status === "success") {
            ElNotification({
              title: "Success",
              message: "Order saved successfully!",
              offset: 150,
              type: "success",
            });
          } else {
            ElNotification({
              title: "Error",
              message: "Something went wrong! Please refresh the page.",
              offset: 150,
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async importMenus(json) {
      json = JSON.parse(json);
      if (!json.menus && !json.categories && !json.products) {
        ElNotification({
          title: "Error",
          message: "Invalid file, please select a valid export file.",
          offset: 150,
          type: "error",
        });
        return;
      }
      await ApiService.post(`/v1/menus/import`, json)
        .then((response) => {
          if (response.data.status === "success") {
            ElNotification({
              title: "Success",
              message: "Menus imported successfully!",
              offset: 150,
              type: "success",
            });
          } else {
            ElNotification({
              title: "Error",
              message: "Something went wrong! Please refresh the page.",
              offset: 150,
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
});
