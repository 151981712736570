import { defineStore } from "pinia";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";

export const useCustomers = defineStore("customers", {
  state: () => ({
    _customers: [],
    _customer: null,
    _reviews: [],
    _count: 0,
    _review_count: 0,
    _loading: false,
  }),
  getters: {
    customers(state) {
      return state._customers;
    },
    customer(state) {
      return state._customer;
    },
    reviews(state) {
      return state._reviews;
    },
    review_count(state) {
      return state._review_count;
    },
    count(state) {
      return state._count;
    },
    review_pages(state) {
      return Math.ceil(state._review_count / 10);
    },
    pages(state) {
      return Math.ceil(state._count / 10);
    },
    loading(state) {
      return state._loading;
    },
  },
  actions: {
    async fetchCustomers(page: number, limit: number) {
      this._loading = true;
      await ApiService.get(`/v1/customers?page=${page}&limit=${limit}`)
        .then((response) => {
          this._customers = response.data.payload;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this._loading = false;
        });
    },
    async fetchCustomer(id: number | string) {
      await ApiService.get(`/v1/customers/${id}`)
        .then((response) => {
          response.data.payload.address = JSON.parse(
            response.data.payload.address
          );
          this._customer = response.data.payload;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    searchReviews(query: string) {
      this._loading = true;
      ApiService.get(`/v1/reviews/?search=${query}&page=1&limit=10`)
        .then((response) => {
          this._reviews = response.data.payload;
          this._review_count = response.data.total;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this._loading = false;
        });
    },
    async fetchReviews(page: number | string, limit: number | string = 10) {
      this._loading = true;
      await ApiService.get(`/v1/reviews/?page=${page}&limit=${limit}`)
        .then((response) => {
          this._reviews = response.data.payload;
          this._review_count = response.data.total;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this._loading = false;
        });
    },
    async editCustomer(customer) {
      this._loading = true;
      await ApiService.patch(`/v1/customers/`, customer)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this._loading = false;
        });
    },
    async deleteCustomer(id: number | string) {
      await ApiService.delete(`/v1/customers/${id}`)
        .then((response) => {
          if (response.data.status === "success") {
            this.fetchCount();
            this.fetchCustomers(1, 10);

            ElNotification({
              title: "Success",
              message: "Customer deleted successfully!",
              offset: 150,
              type: "success",
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async searchCustomers(query: string) {
      this._loading = true;
      await ApiService.get(`/v1/customers/?search=${query}`)
        .then((response) => {
          this._customers = response.data.payload;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this._loading = false;
        });
    },
    async fetchCount() {
      await ApiService.get("/v1/customers?count=1").then((response) => {
        this._count = response.data.payload;
      });
    },
  },
});
