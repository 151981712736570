import { defineStore } from "pinia";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";

export const useCategories = defineStore("categories", {
  state: () => ({
    _categories: [],
    _category: {},
    _count: 0,
    _loading: false,
  }),
  getters: {
    categories(state) {
      return state._categories;
    },
    category(state) {
      return state._category;
    },
    count(state) {
      return state._count;
    },
    loading(state) {
      return state._loading;
    },
  },
  actions: {
    async fetchCategories() {
      this._loading = true;
      await ApiService.get("/v1/categories")
        .then((response) => {
          this._categories = response.data.payload;
          this._count = response.data.payload.length;
          return this._categories.sort((a, b) => {
            return a.order - b.order;
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this._loading = false;
        });
    },
    async createCategory(category) {
      this._loading = true;
      await ApiService.post(`/v1/categories`, category)
        .then((response) => {
          this._categories.push(response.data.payload);
          ElNotification({
            title: "Success",
            message: "Category created successfully!",
            offset: 150,
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          ElNotification({
            title: "Error",
            message: error.response.data.message,
            offset: 150,
            type: "error",
          });
          return false;
        })
        .finally(() => {
          this._loading = false;
        });
    },
    async editCategory(category) {
      this._loading = true;
      await ApiService.patch(`/v1/categories/`, category)
        .then((response) => {
          // remove old category
          this._categories = this._categories.filter(
            (c) => c.id !== category.id
          );

          // add new category
          this._categories.push(response.data.payload);

          ElNotification({
            title: "Success",
            message: "Category updated successfully!",
            offset: 150,
            type: "success",
          });
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this._loading = false;
        });
    },
    async deleteCategory(id: number | string) {
      await ApiService.delete(`/v1/categories/${id}`)
        .then((response) => {
          if (response.data.status === "success") {
            this._categories.splice(
              this._categories.findIndex((menu) => menu.id === id),
              1
            );
            this._count = this._categories.length;

            ElNotification({
              title: "Success",
              message: "Category deleted successfully!",
              offset: 150,
              type: "success",
            });
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this._loading = false;
        });
    },
    async saveOrder(categories) {
      this._loading = true;
      await ApiService.put(`/v1/categories/`, categories)
        .then(() => {
          ElNotification({
            title: "Success",
            message: "Order saved successfully!",
            offset: 0,
            position: "bottom-left",
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          console.error(error);
          return false;
        })
        .finally(() => {
          this._loading = false;
        });
    },
  },
});
