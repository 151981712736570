import { useLayout } from "@/store/layout";

/**
 * Sets current page breadcrumbs
 * @param {string} pageTitle Current page title
 * @param {Array<string>} breadcrumbs Current page breadcrumbs
 */
export const setCurrentPageBreadcrumbs = (
  pageTitle: string,
  /* eslint-disable-next-line */
  breadcrumbs: Array<string> | any
): void => {
  const layout = useLayout();
  layout.$patch({ title: pageTitle, breadcrumbs: breadcrumbs });
  document.title = layout.getTitle;
};

/**
 * Sets current page breadcrumbs
 * @param {string} title Current page title name
 */
export const setCurrentPageTitle = (title: string): void => {
  const layout = useLayout();
  layout.$patch({ title });
  document.title = layout.getTitle;
};
