import { createI18n } from "vue-i18n";
import English from "../../locales/en.js";
import French from "../../locales/fr.js";

const messages = {
  en: English,
  fr: French,
};

const i18n = createI18n({
  legacy: false,
  locale: "en",
  globalInjection: true,
  messages,
  fallbackWarn: false,
  missingWarn: false,
});

export default i18n;
