import { defineStore } from "pinia";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";

export const useStaffs = defineStore("staffs", {
  state: () => ({
    _staffs: [],
    _count: 0,
    _loading: false,
  }),
  getters: {
    staffs(state) {
      return state._staffs;
    },
    count(state) {
      return state._count;
    },
    pages(state) {
      return Math.ceil(state._count / 10);
    },
    loading(state) {
      return state._loading;
    },
  },
  actions: {
    async fetchStaffs(page: number, limit: number) {
      this._loading = true;
      await ApiService.get(`/v1/staffs?page=${page}&limit=${limit}`)
        .then((response) => {
          this._staffs = response.data.payload;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this._loading = false;
        });
    },
    async editStaff(staff) {
      this._loading = true;
      await ApiService.patch(`/v1/staffs/`, staff)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this._loading = false;
        });
    },
    async createStaff(staff) {
      this._loading = true;
      await ApiService.post(`/v1/staffs/`, staff).then((response) => {
        if (response.data.status === "success") {
          this.fetchCount();
          this.fetchStaffs(1, 10);

          ElNotification({
            title: "Success",
            message: "Staff created successfully!",
            offset: 150,
            type: "success",
          });

          return true;
        } else {
          ElNotification({
            title: "Error",
            message: response.data.message,
            offset: 150,
            type: "error",
          });

          return false;
        }
      });
    },
    async deleteStaff(id: number | string) {
      await ApiService.delete(`/v1/staffs/${id}`)
        .then((response) => {
          if (response.data.status === "success") {
            this.fetchCount();
            this.fetchStaffs(1, 10);

            ElNotification({
              title: "Success",
              message: "Staff deleted successfully!",
              offset: 150,
              type: "success",
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async searchStaffs(query: string) {
      this._loading = true;
      await ApiService.get(`/v1/staffs/?search=${query}`)
        .then((response) => {
          this._staffs = response.data.payload;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this._loading = false;
        });
    },
    async fetchCount() {
      await ApiService.get("/v1/staffs?count=1").then((response) => {
        this._count = response.data.payload;
      });
    },
  },
});
