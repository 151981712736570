import ApiService from "@/core/services/ApiService";
import { defineStore } from "pinia";
import { ElNotification } from "element-plus";

export const useAuth = defineStore("auth", {
  state: () => ({
    _token: window.localStorage.getItem("token"),
    _user: window.localStorage.getItem("user"),
    _isAuthenticated: window.localStorage.getItem("token") ? true : false,
    _data: null,
  }),
  getters: {
    token(state) {
      return state._token;
    },
    user(state) {
      return state._user;
    },
    isAuthenticated(state) {
      return state._isAuthenticated;
    },
    data(state) {
      return state._data;
    },
  },
  actions: {
    async fetchUser() {
      await ApiService.get("/v1/staffs?id=" + this._user).then((response) => {
        this._data = response.data.payload;
      });
    },

    async updateProfile(data) {
      await ApiService.patch("/v1/staffs/", { ...data, id: this.user })
        .then(() => {
          this.fetchUser();

          ElNotification({
            title: "Success",
            message: "Profile updated successfully!",
            offset: 150,
            type: "success",
          });
        })
        .catch((error) => {
          ElNotification({
            title: "Error",
            message: error.response.data.message,
            offset: 150,
            type: "error",
          });
        });
    },
  },
});
