import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
// https://firebase.google.com/docs/web/setup#available-libraries

// Initialize Firebase
const app = initializeApp({
  apiKey: "AIzaSyAfHf0uzTKJCb_eQ1sBj2yJyEG-UH0otfE",
  authDomain: "com-scholege-core.firebaseapp.com",
  databaseURL: "https://com-scholege-core.firebaseio.com",
  projectId: "com-scholege-core",
  storageBucket: "com-scholege-core.appspot.com",
  messagingSenderId: "364306157297",
  appId: "1:364306157297:web:df5ef4ca4f9a6a6a666350",
  measurementId: "G-WMBH4KRG1G",
});

const messaging = getMessaging(app);
const token = getToken(messaging, {
  vapidKey:
    "BLgjpVljeGHgj_2guCLDKzSfBtfm8KLNzonTkRTTTX1zNyiEbi1sLy8d39nLk-719_OyNFxHVXxg04xLZDz1kFg",
});

// listen for token refresh

export { messaging, token };
