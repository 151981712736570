import { defineStore } from "pinia";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";

export const useOffers = defineStore("offers", {
  state: () => ({
    _offers: [],
    _count: 0,
    _currentPage: 1,
    _query: "",
    _loading: false,
  }),
  getters: {
    offers(state) {
      if (state._query) {
        return state._offers.filter((offer) =>
          offer.name.toLowerCase().includes(state._query.toLowerCase())
        );
      } else {
        const start = (state._currentPage - 1) * 10;
        const end = start + 10;
        return state._offers.slice(start, end);
      }
    },
    count(state) {
      return state._count;
    },
    pages(state) {
      return Math.ceil(state._count / 10);
    },
  },
  actions: {
    async fetchOffers() {
      this._loading = true;
      await ApiService.get("/v1/offers")
        .then((response) => {
          this._offers = response.data.payload;
          this._count = response.data.payload.length;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this._loading = false;
        });
    },
    async createOffer(offer) {
      this._loading = true;
      await ApiService.post("/v1/offers", offer)
        .then((response) => {
          this._offers.push(response.data.payload);
          this._count++;
          ElNotification({
            title: "Success",
            message: "Offer created successfully",
            offset: 150,
            type: "success",
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this._loading = false;
        });
    },
    async editOffer(offer) {
      this._loading = true;
      await ApiService.patch(`/v1/offers/`, offer)
        .then((response) => {
          if (response.data.status === "success") {
            // remove old item from offers
            this._offers = this._offers.filter((item) => item.id !== offer.id);
            // insert new item to offers
            this._offers.push(response.data.payload);
            ElNotification({
              title: "Success",
              message: "Offer updated successfully!",
              offset: 150,
              type: "success",
            });
          } else {
            ElNotification({
              title: "Error",
              message: "Something went wrong! Please refresh the page.",
              offset: 150,
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this._loading = false;
        });
    },
    async deleteOffer(offer) {
      await ApiService.delete(`/v1/offers/${offer}`)
        .then((response) => {
          if (response.data.status === "success") {
            this._offers.splice(
              this._offers.findIndex((menu) => menu.id === offer),
              1
            );
            this._count = this._offers.length;

            ElNotification({
              title: "Success",
              message: "Offer deleted successfully!",
              offset: 150,
              type: "success",
            });
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this._loading = false;
        });
    },
  },
});
