import { defineStore } from "pinia";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";

export const useSales = defineStore("sales", {
  state: () => ({
    _sales: [],
    _current_year: null,
    _previous_year: null,
    _year_report: {
      sale_count: 0,
      products_sold: 0,
      total_sales: 0,
      total_tax: 0,
      total_discount: 0,
      pickup_count: 0,
      delivery_count: 0,
      cash_count: 0,
      paypal_count: 0,
      card_count: 0,
      pending_count: 0,
      processing_count: 0,
      completed_count: 0,
      cancelled_count: 0,
    },
    _month_report: {
      sale_count: 0,
      products_sold: 0,
      total_sales: 0,
      total_tax: 0,
      total_discount: 0,
      pickup_count: 0,
      delivery_count: 0,
      cash_count: 0,
      paypal_count: 0,
      card_count: 0,
      pending_count: 0,
      processing_count: 0,
      completed_count: 0,
      cancelled_count: 0,
    },
    _count: 0,
    _loading: false,
  }),
  getters: {
    sales(state) {
      return state._sales;
    },
    currentYear(state) {
      return state._current_year;
    },
    previousYear(state) {
      return state._previous_year;
    },
    yearReport(state) {
      return state._year_report;
    },
    monthReport(state) {
      return state._month_report;
    },
    count(state) {
      return state._count;
    },
    pages(state) {
      return Math.ceil(state._count / 10);
    },
    loading(state) {
      return state._loading;
    },
  },
  actions: {
    async fetchSales(page: number, limit: number) {
      this._loading = true;
      await ApiService.get(`/v1/sales?page=${page}&limit=${limit}`)
        .then((response) => {
          this._sales = response.data.payload;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this._loading = false;
        });
    },
    async updateStatus(payload) {
      this._loading = true;
      await ApiService.patch("/v1/sales", payload)
        .then((response) => {
          ElNotification({
            title: "Success",
            message: response.data.message,
            offset: 150,
            type: "success",
          });
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this._loading = false;
        });
    },
    async searchSales(query: string) {
      this._loading = true;
      await ApiService.get(`/v1/sales/?search=${query}`)
        .then((response) => {
          this._sales = response.data.payload;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this._loading = false;
        });
    },
    async fetchCount() {
      await ApiService.get("/v1/sales?count=1").then((response) => {
        this._count = response.data.payload;
      });
    },
    async getYearlyReport() {
      this._loading = true;
      const year = new Date().getFullYear();
      const previous = year - 1;
      await ApiService.get("/v1/sales/yearly-report?year=" + year)
        .then((response) => {
          this._current_year = response.data.payload;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this._loading = false;
        });
      await ApiService.get("/v1/sales/yearly-report?year=" + previous)
        .then((response) => {
          this._previous_year = response.data.payload;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this._loading = false;
        });
    },
    async getRangeReport() {
      this._loading = true;
      let start = new Date();
      start.setDate(start.getDate() - 30);
      const end = new Date();
      console.log(start.toISOString(), end.toISOString());
      await ApiService.get(
        `/v1/sales/report?start_date=${start.toISOString()}&end_date=${end.toISOString()}&yearly=1`
      )
        .then((response) => {
          this._month_report = response.data.payload;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this._loading = false;
        });

      start = new Date();
      start.setFullYear(start.getFullYear() - 1);
      await ApiService.get(
        `/v1/sales/report?start_date=${start.toISOString()}&end_date=${end.toISOString()}`
      )
        .then((response) => {
          this._year_report = response.data.payload;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this._loading = false;
        });
    },
  },
});
