import { defineStore } from "pinia";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";

export const useOptions = defineStore("options", {
  state: () => ({
    _options: [],
    _option: {},
    _count: 0,
    _loading: false,
  }),
  getters: {
    options(state) {
      return state._options;
    },
    option(state) {
      return state._option;
    },
    count(state) {
      return state._count;
    },
    pages(state) {
      return Math.ceil(state._count / 10);
    },
    loading(state) {
      return state._loading;
    },
  },
  actions: {
    async fetchOptions() {
      this._loading = true;
      await ApiService.get("/v1/options")
        .then((response) => {
          this._options = response.data.payload;
          this._options.forEach((option) => {
            option.options = JSON.parse(option.options);
          });
          this._count = response.data.payload.length;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this._loading = false;
        });
    },
    async createOption(option) {
      this._loading = true;
      await ApiService.post(`/v1/options`, option)
        .then((response) => {
          this._options.push(response.data.payload);
          ElNotification({
            title: "Success",
            message: "Option created successfully.",
            type: "success",
          });
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Something went wrong.",
            type: "error",
          });
        })
        .finally(() => {
          this._loading = false;
        });
    },
    async updateOption(option) {
      this._loading = true;
      await ApiService.patch(`/v1/options/`, option)
        .then((response) => {
          this._options = this._options.map((o) => {
            if (o.id === option.id) {
              return response.data.payload;
            }
            return o;
          });
          ElNotification({
            title: "Success",
            message: "Option updated successfully.",
            type: "success",
          });
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Something went wrong.",
            type: "error",
          });
        })
        .finally(() => {
          this._loading = false;
        });
    },
    async deleteOption(id) {
      this._loading = true;
      await ApiService.delete(`/v1/options/${id}`)
        .then(() => {
          this._options = this._options.filter((option) => option.id !== id);
          ElNotification({
            title: "Success",
            message: "Option deleted successfully.",
            type: "success",
          });
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Something went wrong.",
            type: "error",
          });
        })
        .finally(() => {
          this._loading = false;
        });
    },
  },
});
