import { defineStore } from "pinia";

export const useLayout = defineStore("layout", {
  state: () => ({
    title: "",
    breadcrumbs: [],
  }),
  getters: {
    getTitle(state) {
      return `${state.title}`;
    },
  },
  actions: {},
});
