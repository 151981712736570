export default {
  Dashboard: "Dashboard",
  Home: "Home",
  Sales: "Sales",
  Reviews: "Reviews",
  "Menu Manager": "Menu Manager",
  Staffs: "Staffs",
  Customers: "Customers",
  Invoices: "Invoices",
  Marketing: "Marketing",
  Settings: "Settings",

  Name: "Name",
  Email: "Email",
  Phone: "Phone",
  Export: "Export",
  Actions: "Actions",
  Edit: "Edit",
  Delete: "Delete",
  Add: "Add",
  Save: "Save",
  Cancel: "Cancel",
  Back: "Back",
  "Are you sure you want to delete this item?":
    "Are you sure you want to delete this item?",

  "Search Staffs": "Search Staffs",
  "Add Staffs": "Add Staffs",

  "Search Customers": "Search Customers",
  "Add Customers": "Add Customers",

  "Search Invoices": "Search Invoices",

  "Search Reviews": "Search Reviews",

  "Search Sales": "Search Sales",

  "No Data": "No Data",
  "Something went wrong": "Something went wrong",

  "Loading...": "Loading...",

  "Opening Hours": "Opening Hours",
  Security: "Security",
  Payments: "Payments",
  Contacts: "Contacts",
};
