import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("@/views/admin/dashboard/index.vue"),
      },
      {
        path: "/customers",
        name: "Customers",
        component: () => import("@/views/admin/customers/index.vue"),
      },
      {
        path: "/invoices",
        name: "Invoices",
        component: () => import("@/views/admin/invoices/index.vue"),
      },
      {
        path: "/invoices/:id",
        name: "Invoice",
        component: () => import("@/views/admin/invoices/ticket.vue"),
      },
      {
        path: "/marketing",
        name: "Marketing",
        component: () => import("@/views/admin/marketing/index.vue"),
      },
      {
        path: "/menu-manager",
        name: "Menu Manager",
        component: () => import("@/views/admin/menu-manager/index.vue"),
      },
      {
        path: "/menu-manager/:id",
        name: "Menu Editor",
        component: () => import("@/views/admin/menu-manager/edit.vue"),
      },
      {
        path: "/reviews",
        name: "Reviews",
        component: () => import("@/views/admin/reviews/index.vue"),
      },

      {
        path: "/sales",
        name: "Sales",
        component: () => import("@/views/admin/sales/index.vue"),
      },
      {
        path: "/settings",
        name: "Settings",
        component: () => import("@/views/admin/settings/index.vue"),
      },
      {
        path: "/staffs",
        name: "Staffs",
        component: () => import("@/views/admin/staffs/index.vue"),
      },
      {
        path: "/pages",
        name: "Pages",
        component: () => import("@/views/admin/pages/index.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "Sign in",
        component: () => import("@/views/admin/auth/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "Sign up",
        component: () => import("@/views/admin/auth/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "Password reset",
        component: () => import("@/views/admin/auth/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach((to) => {
  document.querySelector(".loading-line")?.classList.remove("active");
  setCurrentPageTitle(to.name as string);
});

router.beforeEach(() => {
  document.querySelector(".loading-line")?.classList.add("active");
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
