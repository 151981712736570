export default {
  Dashboard: "Tableau de bord",
  Home: "Accueil",
  Sales: "Ventes",
  Reviews: "Avis",
  "Menu Manager": "Gestionnaire de menu",
  Staffs: "Personnels",
  Customers: "Clients",
  Invoices: "Factures",
  Marketing: "Marketing",
  Settings: "Paramètres",

  Name: "Nom",
  Email: "Email",
  Phone: "Téléphone",
  Export: "Exportation",
  Actions: "Actions",
  Edit: "Modifier",
  Delete: "Supprimer",
  Add: "Ajouter",
  Save: "Sauvegarder",
  Cancel: "Annuler",
  Back: "Retour",
  "Are you sure you want to delete this item?":
    "Êtes-vous sûr de vouloir supprimer cet élément?",

  "Search Staffs": "Rechercher des personnels",
  "Add Staffs": "Ajouter un personnel",

  "Search Customers": "Rechercher des clients",
  "Add Customers": "Ajouter un client",

  "Search Invoices": "Rechercher des factures",

  "Search Reviews": "Rechercher des avis",

  "Search Sales": "Rechercher des ventes",

  "No Data": "Aucune donnée",
  "Something went wrong": "Quelque chose a mal tourné",

  "Loading...": "Chargement...",

  "Opening Hours": "Heures d'ouverture",
  Security: "Sécurité",
  Payments: "Paiements",
  Contacts: "Contacts",

  Saturday: "Samedi",
  Sunday: "Dimanche",
  Monday: "Lundi",
  Tuesday: "Mardi",
  Wednesday: "Mercredi",
  Thursday: "Jeudi",
  Friday: "Vendredi",
};
